<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="用户名">
                            <el-input v-model="searchForm.user_name" placeholder="用户名" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <el-input v-model="searchForm.phone" placeholder="手机号" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱">
                            <el-input v-model="searchForm.email" placeholder="邮箱" size="small"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getUserList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delUser()">批量删除</el-button>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="user_name" label="名称"></el-table-column>
                <el-table-column label="头像">
                    <template slot-scope="scope"><img :src="scope.row.user_img" alt="用户头像" :width="50" :height="50" /></template>
                </el-table-column>
                <el-table-column prop="sex" label="性别"></el-table-column>
                <el-table-column prop="phone" label="手机"></el-table-column>
                <el-table-column prop="email" label="邮箱"></el-table-column>
                <el-table-column prop="amount" label="吃货币"></el-table-column>
                <el-table-column prop="last_login_time" label="上次登录时间" sortable></el-table-column>
                <el-table-column prop="last_login_ip" label="上次登录ip" sortable></el-table-column>
                <el-table-column prop="create_time" label="注册时间" sortable></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="showForm(scope.row.id)">详情</el-button>
                        <el-button type="danger" size="mini" @click="delUser(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>
        <ViewForm v-if="viewFormDialog" :viewFormDialog="viewFormDialog" :viewFormId="viewFormId" @closeForm="closeForm" @getUserList="getUserList"></ViewForm>
    </div>
</template>

<script>
import { getUserList, delUser } from '@/utils/servers/user.js';
import Pagination from '@/components/Pagination/Pagination.vue';
import ViewForm from './view.vue';
export default {
    components: {
        Pagination,
        ViewForm
    },
    data() {
        return {
            searchForm: {
                user_name: '',
                phone: '',
                email: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            viewFormDialog: false,
            viewFormId: 0
        };
    },
    created() {
        this.getUserList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getUserList();
        },
        getUserList() {
            getUserList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delUser(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delUser({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getUserList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        showForm(id = 0) {
            this.viewFormDialog = true;
            this.viewFormId = id;
        },
        closeForm() {
            this.viewFormDialog = false;
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
