<template>
    <el-dialog :visible.sync="formDialog" title="详情" @close="closeForm">
        <el-row>
            <el-col :span="12">
                <el-form ref="dataForm" :model="dataForm" label-width="100px" class="data-form">
                    <el-form-item label="ID">
                        <p>{{ dataForm.id }}</p>
                    </el-form-item>
                    <el-form-item label="openid">
                        <p>{{ dataForm.openid }}</p>
                    </el-form-item>
                    <el-form-item label="头像">
                        <img :src="dataForm.user_img" alt="用户头像" :width="50" :height="50" />
                    </el-form-item>
                    <el-form-item label="名称">
                        <p>{{ dataForm.user_name }}</p>
                    </el-form-item>
                    <el-form-item label="性别">
                        <p>{{ dataForm.sex }}</p>
                    </el-form-item>
                    <el-form-item label="手机">
                        <p>{{ dataForm.phone }}</p>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <p>{{ dataForm.email }}</p>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12">
                <el-form ref="dataForm" :model="dataForm" label-width="100px" class="data-form">
                    <el-form-item label="等级">
                        <p>{{ dataForm.level }}</p>
                    </el-form-item>
                    <el-form-item label="粉丝">
                        <p>{{ dataForm.follower }}</p>
                    </el-form-item>
                    <el-form-item label="勋章">
                        <p>{{ dataForm.medal }}</p>
                    </el-form-item>
                    <el-form-item label="经验">
                        <p>{{ dataForm.exp }}</p>
                    </el-form-item>
                    <el-form-item label="关注">
                        <p>{{ dataForm.following }}</p>
                    </el-form-item>
                    <el-form-item label="成就">
                        <p>{{ dataForm.achievement }}</p>
                    </el-form-item>
                    <el-form-item label="吃货币">
                        <p>{{ dataForm.amount }}</p>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import { getUserInfo } from '@/utils/servers/user.js';
export default {
    props: ['viewFormDialog', 'viewFormId'],
    data() {
        return {
            formDialog: this.viewFormDialog,
            dataForm: {}
        };
    },
    created() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            getUserInfo(this.viewFormId).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dataForm = data.data;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        closeForm() {
            this.$emit('closeForm');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
